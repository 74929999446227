@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;



body{
    font-family: "Poppins", sans-serif;
}